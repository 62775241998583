.main__header {
  width: 100vw;
  height: calc(100vh - 3.49rem);
  display: grid;
  margin-top: 3rem;
}

.main__header-container {
  text-align: center;
  z-index: 1;
}

.main__header-background {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* fill the viewport */
  background-image: url("../../images/home/mprc_home.jpg");
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
}

.main__header-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.main__header-container h1,
.main__header-container p {
  color: #fff;
  margin: 0;
}

.main__header-container h1 {
  margin-bottom: 1rem;
  font-weight: 500;
}

.main__header-container p {
  font-size: 1.1rem;
  margin-bottom: 3.5rem;
}

/******************** PROGRAMS STYLES ********************/
.programs {
  margin-top: 4rem;
}

.program__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-top: 4rem;
}

.programs__program a {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.programs__program:hover a {
  background: var(--color-secondary);
  color: var(--color-gray-600);
}

/******************** VALUES STYLES ********************/
.values__container {
  display: grid;
  grid-template-columns: 38% 50%;
  gap: 12%;
}

.values__image {
  filter: saturate(0.25);
  transition: var(--transition);
}

.values__image:hover {
  filter: saturate(1);
  transition: var(--transition);
}

.values__right > p {
  margin: 1.5rem 0 5rem;
}

.values__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.4rem 2.4rem; /*3.4 for row 2.4 for column*/
}

.card.values__value {
  /*.card from index.css UI component*/
  padding-top: 3.5rem;
  text-align: left;
  position: relative;
}

.card.values__value span {
  position: absolute;
  top: -1.5rem;
}

/******************** FAQ STYLES ********************/

.faqs__wrapper {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 5rem;
  /* flex start makes it align automatically even when not clicked on */
  align-items: flex-start;
}

.faq {
  background: var(--color-gray-500);
  padding: 2rem;
  border-radius: 1rem;
  cursor: pointer;
}

.faq div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq__icon {
  background: transparent;
  color: var(--color-gray-100);
  font-size: 1.5rem;
}

.faq p {
  margin-top: 1.5rem;
}

/******************** TESTIMONIAL STYLES ********************/

.testimonials {
  width: 50rem;
  margin-inline: auto; /*make it at the middle*/
}

.testimonials__head {
  justify-content: center;
}

.card.testimonial {
  position: relative;
  text-align: left;
  margin-top: 5rem;
}

.testimonial__avatar {
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: 1.5rem;
  border: 2px solid transparent;
  overflow: hidden;
  top: -2rem;
  left: calc(50% - 2rem);
  box-shadow: 0 1.5rem 2rem rgba(26, 26, 54, 0.4);
  transition: var(--transition);
}

.testimonial:hover .testimonial__avatar {
  border-radius: 50%;
  border-color: var(--color-gray-400);
}

.testimonial__quote {
  font-style: italic;
  margin: 1rem 0 2rem;
}

.card small.testimonial__title {
  margin-top: 0.3rem;
}

.testimonial__btn-container {
  width: fit-content;
  margin: 2.5rem auto 0;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.testimonials__btn {
  background: transparent;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.testimonials__btn svg {
  fill: var(--color-primary);
  font-size: 1.8rem;
  width: 3rem;
  height: 3rem;
}

/******************** FOOTER STYLES ********************/
footer {
  background: var(--color-primary);
  margin-top: 7rem;
  padding-top: 3rem;
  font-size: 0.9rem;
  color: var(--color-gray-100);
}

.footer__container {
  display: grid;
  grid-template-columns: 26rem 1fr;
  gap: 20rem;
}

.footer__container article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.7rem;
}

.footer__container article p {
  margin-top: 0.5rem;
}

.footer__container article h4 {
  margin-bottom: 0.6rem;
  font-size: 1rem;
}

.footer__socials {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer__socials a {
  background: var(--color-gray-100);
  padding: 0.3rem 0.7rem;
  border: 1px solid transparent;
  border-radius: 0.6rem;
  transition: var(--transition);
}

.footer__socials a svg {
  color: var(--color-gray-600);
  font-size: 1rem; /* Adjust the size as needed */
}

.footer__socials a:hover {
  border-color: var(--color-gray-100);
  background: transparent;
}

.footer__copyright {
  color: var(--color-gray-100);
  text-align: center;
  padding: 1.5rem 0;
  border-top: 2px solid var(--color-primary-variant);
  margin-top: 3rem;
}

/******************** MEADIA QUERIES (medium screens) ********************/
@media screen and (max-width: 1024px) {
  /* FOOTER */
  .footer__container {
    grid-template-columns: 1fr 1fr;
    gap: 10rem;
  }
}

/******************** MEADIA QUERIES (small screens) ********************/
@media screen and (max-width: 600px) {
  .footer {
    margin-top: 7rem;
  }

  .footer__container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .footer__container article {
    align-items: center;
  }

  .footer__container article p {
    text-align: center;
  }
}
