.about__story {
  margin-top: 8rem;
}

.about__mission {
  margin-top: 3rem;
}

.about__section-content h2 {
  margin-bottom: 1rem;
  text-align: center;
}

.about__section-content p {
  margin-bottom: 1rem;
}

.img_section {
  margin-top: 3rem;
  text-align: center; /* Center the content horizontally */
}

.img_section img {
  width: 80%;
  display: block; /* Ensure the image is a block element */
  margin: 0 auto; /* Center the image horizontally */
}

.img_section h2 {
  margin-bottom: 10px; /* Add spacing between the heading and image */
}

.join_btn_section {
  text-align: center;
}
