#activities {
  margin: 80px;
}
.video-container {
  display: flex;
  flex-wrap: wrap;
}

.video-item {
  width: 100%;
  height: 250px; /* Set default height for mobile */
  max-width: 450px; /* Set maximum width for larger screens */
  margin-bottom: 20px; /* Add vertical spacing between videos */
}

@media (min-width: 768px) {
  .video-item {
    width: calc(50% - 20px); /* Two videos side by side with spacing */
    height: auto; /* Reset height for larger screens */
    margin-right: 20px; /* Add horizontal spacing between videos */
    margin-bottom: 0; /* Remove vertical margin for larger screens */
  }

  .video-item:last-child {
    margin-right: 0; /* Remove margin from the last video to prevent overflow */
  }
}


