nav {
  height: 5.5rem;
  width: 100vw; /*100% of the window/browser*/
  background: var(--color-primary);
  display: grid;
  place-items: center;
  position: fixed; /*commnt this to see hiden layers*/
  top: 0;
  left: 0;
  z-index: 99; /* makes sure it sit on all items*/
}

/* Only show on medium and small screens */
.nav__toggle-btn {
  display: none;
}

.nav__container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  width: 4.5rem;
  display: block;
}

.nav__links {
  display: flex;
  gap: 3.5rem;
  align-items: center;
}

.nav__links a {
  transition: var(--transition);
}

.nav__links a:hover {
  color: var(--color-secondary);
}

.active-nav {
  position: relative;
}

.active-nav::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--color-primary);
  position: absolute;
  left: calc(50% - 0.6rem);
  transform: rotate(45deg);
  margin-top: 0.9rem;
}

/******************** MEDIA QUERIES (mesium and small screens) ********************/
@media screen and (max-width: 1024px) {
  .nav__toggle-btn {
    display: inline-block;
    background: transparent;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav__toggle-btn svg {
    color: var(--color-gray-100);
  }

  .nav__links {
    position: absolute;
    top: 100%;
    right: 0;
    flex-direction: column;
    gap: 0;
    perspective: 400px;
  }

  .active-nav {
    display: none;
  }

  .active-nav,
  .active-nav::after {
    display: none;
  }

  .nav__links li {
    height: 4rem;
    width: 100%;
    box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.5);
    animation: navAnimation 500ms ease forwards;
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: top;
  }

  @keyframes navAnimation {
    to {
      transform: rotateX(0);
      opacity: 1;
    }
  }

  .nav__links li a {
    background-color: var(--color-primary);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 5rem 1rem 3rem;
  }

  .show__nav {
    display: flex;
  }

  .hide__nav {
    display: none;
  }
}
