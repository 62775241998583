.ul_joinus .li_joinus {
  list-style-type: disc;
  text-align: left;
}

/* Style for the nested list items */
.ul_joinus .ul_joinus li {
  list-style-type: circle; /* Use circle for nested bullets */
  margin-left: 1.5rem; /* Adjust the indentation as needed */
}

.joinus__container {
  display: grid;
  place-items: center;
}

.joinus__wrapper {
  width: fit-content;
  gap: 2rem;
}

@media screen and (max-width: 600px) {
  .joinus__wrapper {
    gap: 1.5rem;
  }
}

.h2_joinus {
  padding-bottom: 2rem;
}

.ul_joinus {
  margin-bottom: 3rem;
}

.route-map {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head_desc {
  text-align: center;
}