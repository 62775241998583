.officers__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.card.officer {
  padding: 1rem;
}

.officer__img {
  border-radius: 0 2rem;
  overflow: hidden;
}

.officer h3 {
  margin-top: 2rem;
}

.officer p {
  margin-top: 0.5rem;
}

.officer__socials {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.officer__socials a {
  font-size: 0.7rem;
  background-color: var(--color-gray-600);
  padding: 0.7rem 0.8rem;
  border-radius: 0.7rem;
  transition: var(--transition);
}

.officer:hover a {
  background: var(--color-gray-500);
}

/* MEDIA QUERIES (medium sxreen) */
@media screen and (max-width: 1024px) {
  .officers__container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* MEDIA QUERIES (small sxreen) */
@media screen and (max-width: 600px) {
  .officers__container {
    grid-template-columns: repeat(2, 2fr);
  }
}

@media screen and (max-width: 400px) {
  .officers__container {
    grid-template-columns: 1fr;
  }

  .card.officer {
    width: 90%;
    margin-inline: auto;
  }
}
